import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types'
import { Typography, TextField, Link, IconButton, Stack, Paper } from '@mui/material';
import { FavoriteOutlined, FavoriteBorderSharp } from '@mui/icons-material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { API } from 'aws-amplify';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const apiName = 'TreasureBoxAPI';

export function PostCommentsComponent(props) {
  const { postId, likes, comments, onLikesUpdated, onCommentsUpdated, lang } = props
  // likes is a number
  const hasBeenLiked = sessionStorage.getItem('mytb' + postId)
  const [liked, setLiked] = useState(hasBeenLiked) //sessionStorage.getItem('mytb'+postId)
  const [addCommentOpen, setAddCommentOpen] = React.useState(false);
  const [commentsOpen, setCommentsOpen] = React.useState(false);
  const [userComments, setUserComments] = React.useState('')

  const likeOrLikes = likes > 1 ? 'likes' : 'like'
  const addCommentString = lang === 'rom' ? 'Adauga comentariu' : 'Add comment'
  const viewCommentsString = lang === 'rom' ? 'Vezi comentarii' : 'View comments'
  const noCommentsString = lang === 'rom' ? 'Nici un comentariu' : 'No comments yet'
  const hereAreTheCommentsString = lang === 'rom' ? 'Acestea sunt comentariile' : 'Here are some of your comments'
  const commentsAnonString = lang === 'rom' ? 'Comentariile vor fi postate anonim' : 'Any comments will be posted anonymously.'
  const getInTouch = lang === 'rom' ? 'Daca doriti sa ma contactati, va astept la ' : 'I would love it if you get in touch:'
  const cancelString = lang === 'rom' ? 'Anuleaza' : 'Cancel'
  const saveString = lang === 'rom' ? 'Salveaza' : 'Save'
  const handleAddCommentClickOpen = (e) => {
    e.stopPropagation()
    setUserComments('')
    setAddCommentOpen(true);
  };

  const handleAddCommentSave = async (e) => {
    e.stopPropagation()
    setAddCommentOpen(false);
    const body = {
      commentsId: 'comment' + Math.floor(Math.random() * 1000000),
      postId,
      comment: userComments,
      when: new Date()
    }
    try {
      const res = await API.post(apiName, '/comments', {
        body
      })
      onCommentsUpdated(res)
    }
    catch (e) {
      console.error(e)
    }
  };
  const handleViewCommentsClickOpen = (e) => {
    e.stopPropagation()
    setCommentsOpen(true)
  };

  const handleCommentsClickClose = async (e) => {
    e.stopPropagation()
    setCommentsOpen(false)
  };
  const anyComments = () => {
    const separator = '~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~'
    if (!comments || comments?.length === 0) return ''
    let commentsAsString = ''

    for (let i = 0; i < comments.comments?.length; i++) {
      const comment = comments.comments[i].trim()
      if (comment.length > 0) {
        if (i === 0) {
          commentsAsString = `${separator}\n${comment}`
        } else {
          commentsAsString = `${commentsAsString}\n${separator}\n${comment}`
        }
      }
    }
    commentsAsString = `${commentsAsString}'\n${separator}\n\n`
    return commentsAsString
  }
  const commentsAsString = anyComments()
  return (
    <>
      {/* <Stack direction='row' spacing={1} sx={{ textAlign: 'center' }}> */}
      <IconButton
        key={'iconButtonlike'}
        aria-label='add'
        sx={{ color: 'red' }}
        onClick={async e => {
          sessionStorage.setItem('mytb' + postId, liked)
          setLiked(!liked)
          const body = {
            likesId: 'like' + Math.floor(Math.random() * 1000000),
            postId,
            liked: !liked,
            when: new Date()
          }
          try {
            const res = await API.post(apiName, '/likes', {
              body
            })
            /* res is {
    "metaphor": {
      "likes": [
          true,
          true,
          true
      ]
  },
  "letters": {
      "likes": [
          true,
          true
      ]
  },
  "#intro": {
      "likes": [
          true,
          true
      ]
  },
  "tablecloth": {
      "likes": [
          false,
          "false",
          true,
          false,
          false,
          true,
          true
      ]
  },
  "bookswar": {
      "likes": [
          true,
          true
      ]
  },
  "battles": {
      "likes": [
          false,
          true
      ]
  }
}
*/
            let likesModified = {}

            Object.keys(res).map((likeKey) => {
              const likeObj = res[likeKey]
              if (likeObj.likes) {
                const counter = likeObj.likes.reduce((counter, liked) => {
                  if (liked) {
                    counter += 1
                  }
                  else { counter -= 1 }
                  return counter;
                }, 0);
                likesModified[likeKey] = Math.max(counter, 0)
              }
              onLikesUpdated(likesModified)

            })
          }
          catch (e) {
              console.error(e)
            }
            
          }}
      >
        {!liked ? <FavoriteBorderSharp /> : <FavoriteOutlined />}
      </IconButton>
      {/* <Typography sx={{ paddingTop: '6px' }}> */}
      {likes > 0 && `${likes} ${likeOrLikes}`}
      {/* </Typography> */}
      {/* </Stack> */}
      <Stack direction='row' spacing={1} sx={{ textAlign: 'center' }}>

        <Link
          onClick={handleAddCommentClickOpen}
          component="button"
          variant="body2"
          underline="always"
          key='ratebutton'
          sx={{
            display: 'block',
            paddingTop: '5px',
            fontWeight: 'bold',
            size: '20px',
            // color: 'blue'
            //textAlign: 'center'
          }}

        >
          {addCommentString}
        </Link>

        <Link
          onClick={handleViewCommentsClickOpen}
          underline="always"
          component="button"
          variant="body2"
          key='commentsbutton'
          sx={{
            display: 'block',
            paddingTop: '5px',
            fontWeight: 'bold',
            // color: 'blue'
            //textAlign: 'center'
          }}

        >
          {viewCommentsString}
        </Link>
        <Dialog
          open={addCommentOpen}
          TransitionComponent={Transition}
          keepMounted
          scroll='paper'
          onClose={handleAddCommentSave}
          aria-describedby="alert-dialog-slide-description"
          key='ratingDialog'
        >
          <DialogTitle>{addCommentString}</DialogTitle>
          <DialogContent sx={{ padding: '10px' }}>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>{commentsAnonString}<br />

              </Typography>

              <TextField fullWidth={true}
                maxRows={5}
                minRows={5}
                inputProps={{ maxLength: 250 }}
                multiline
                value={userComments}
                onChange={(event) => {
                  event.stopPropagation()
                  setAddCommentOpen(true)
                  setUserComments(event.target.value)
                }}
              >

              </TextField>
              <Typography>
                {getInTouch} <Link href="https://www.instagram.com/mytreasurebox_rom/" target='_blank' style={{ color: "black" }}>
                  <InstagramIcon />
                </Link>
              </Typography>
              <br />
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={(e) => {
              e.stopPropagation()
              setAddCommentOpen(false)
            }} sx={{ color: 'black' }}>{cancelString}</Button>
            <Button onClick={handleAddCommentSave} variant="outlined" sx={{ color: 'black' }} >{saveString}</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={commentsOpen}
          TransitionComponent={Transition}
          keepMounted
          scroll='paper'
          onClose={handleCommentsClickClose}
          aria-describedby="alert-dialog-slide-description"
          key='commentsdialog'
        >
          <DialogTitle> {commentsAsString === '' ? noCommentsString : hereAreTheCommentsString}</DialogTitle>
          {commentsAsString !== '' && <DialogContent>
            <DialogContentText sx={{ whiteSpace: 'pre-line', border: 'blue' }} id="alert-dialog-slide-description">
              <>
                <Paper variant="outlined">
                  <Typography variant='body1' sx={{ paddingBottom: '10px' }}
                    component='div'
                    key={'comments'}
                  >
                    {commentsAsString}
                  </Typography>

                </Paper>
              </>
            </DialogContentText>

          </DialogContent>}
          <DialogActions>
            <Button onClick={handleCommentsClickClose} variant='outlined' >Close</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
}

PostCommentsComponent.displayName = 'PostCommentsComponent'

PostCommentsComponent.propTypes = {

  postId: PropTypes.string

}

export default PostCommentsComponent
