import React from "react";
import { Container } from 'react-bootstrap';


const PostPage = ({ urlSearchParameters, lang, linkId, text, leftLinkId, leftLinkText, rightLinkId, rightLinkText, likes, comments, onLikesUpdated, onCommentsUpdated }) => {
  
    return (
        <>
            <Container>
                <br/>
                <br/>
                Recipe 1
                ...
                Recipe 2
                ...
            </Container>
        </>
    )
}


export default PostPage;