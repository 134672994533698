
import Post7Summary from "../summary/Post7Summary";

const Post7 = (lang) => {
	let content
	if (lang === 'rom') {
		const romText = `
# Taica Popa, “Un om intre oameni”

În satele românești de altădată existau două pozitii foarte respectate și care aveau o mare influență socială. 
Aceștia erau preotul și invatatorul din sat (deseori era unul singur!). E clar ca au fost si invatatoare, [strabunica mea](./letters), de exemplu, a fost "Doamna" (in sensul de doamna invatatoare) intr-un sat.<br> 
Diverse romane românești din secolele XIX și XX au cel puțin câteva povesti, dacă nu cea principală, despre aspirațiile și dificultatilor unar băieți mai săraci care aspira la aceste poziții. 
Era o formă de avansare socială, scăpau de munca brutala a pământului, dar în acelasi timp se întorceau totuși la locul nașterii, de obicei spre bucuria părinților, mândri că fiii lor au urcat pe scara socială.
Din păcate, mi se pare greu de imaginat acest respect astăzi, mai ales pentru profesori.

Au fost mulți invatatori, profesori în familia mea extinsă, dar un singur preot. 
Se numea Florea (versiunea masculină a numelui dat surorii sale, Floarea/Flora a cărei poveste am scris-o [aici](./tablecloth) - părinților lor trebuie să le fi plăcut foarte mult florile!).
Era cunoscut ca Taica Popa atât în ​​familie, cât și in comunitate.
Avea ceva deosebit: carisma sau har sau vocatie.

A fost fratele mai mic al străbunicii mele, Constanta (povestea ei [aici](./letters)) și avea o prezenta impresionantă. L-am cunoscut în copilărie și îmi amintesc de el ca pe un bărbat înalt, încă impunător, cu barbă și păr alb, ochi albaștri blinzi și voce domoala, dar cu un aer de autoritate și cunoaștere profundă. Cei din jur ii arătau un mare respect. 
Avea vreo 80 de ani atuncit, evident că îmi părea la fel de bătrân ca timpul, o viață întreagă de credință, suferință și înțelepciune gravată pe chipul lui.
Cea mai mare parte a vieții a fost preot într-un sat din județul Argeș, locul natal atât al lui, cât și al Constantei, un loc la aproximativ 50 km de satul în care a locuit Constanța după căsătorie și apoi cu fiica ei Aurelia.

Constanta, ca văduvă tânără, și-a vizitat fratele cât de des a putut pentru a ii cere sfaturi și a obține sprijin moral și practic. 
Acest lucru a continuat de-a lungul vieții ei. Când bunica mea, Aurelia s-a întors să stea cu mama ei în anii celui de-al doilea război mondial, 
a lucrat cu unchiul ei la diverse proiecte caritabile sociale, culturale și educaționale.
Câteva fotografii din acea perioadă au supraviețuit.

<figure>
<img src="./post7/emptychair.jpg"
	style="display: block; margin: auto; width: 300px" />
</figure>

Acesta îl arată pe Părinte așezat cu Aurelia în picioare în spatele scaunului. 
Sunt intrigata de scaunul gol. A însemnat ceva? Cineva absent? 
A fost menit să arate o anumită ierarhie socială sau morală în modul și ordinea în care se află oamenii? 
De ce nu s-a așezat Aurelia lângă el? Ar fi fost considerat lipsit de respect din cauza poziției lui? 
Dar atunci de ce să nu fi mutat scaunul iar  ceilalți să stea în spatele lui? 
Obiceiuri ale familiei și ale societății de atunci sunt reprezentate în aceasta mica fotografie, dar nu a mai rămas nimeni care să le poată explica.

<figure>
<img src="./post7/mtg.png"
	style="display: block; margin: auto; width: 300px" />
</figure>

O altă fotografie la un astfel de eveniment îi arată pe Părinte și Aurelia (în dreapta, puțin la umbră, îmbrăcața într-o bluză tradițională românească).

Taica popa a avut o perioadă foarte dificilă când comuniștii au venit la putere, având în vedere combinația dintre statutul social și economic al familiei și, bineînțeles, credința și poziția sa de preot.
După cum este descris în surse precum [aceasta](https://en.wikipedia.org/wiki/Anti-Religious_Campaign_in_communist_Romania)
doctrina ateismului marxist-leninist a luat o poziție ostilă împotriva religiei și și-a pus ochii pe scopul final al unei societăți atee în care religia să fie recunoscută ca ideologie a burgheziei. 
Au fost o mulțime de preoți ortodocși și oficialități bisericești de rang înalt care au colaborat cu autoritățile comuniste, dar Părintele a fost printre cei 5.000 de preoți creștini ortodocși români care au fost întemnițați. 
În 1962–1964, conducerea partidului a aprobat o amnistie în masă, extinsă, printre alți prizonieri, la cca. 6.700 „vinovați” de crime politice.

Unul dintre lucrurile mele preferate legate de Taica Popa a fost că în 1969 a reușit cumva (bănuiesc că a cerut niște favoruri de la colegii preoți) să vină la București să facă, împreună cu parohul local, 
ceremonia de căsătorie pentru părinții mei.

<figure>
<img src="./post7/wedding.jpg"
	style="display: block; margin: auto; width: 300px" />
</figure>
Acesta este Taica Popa cu părinții mei purtând coroanele de nuntă ortodoxe, mirii, regele și regina noii familii, cu tânărul meu tată care arată încântat și mama solemnă. 
A însemnat foarte mult pentru mama mea să-l aibă pe Taica Popa acolo, singura rudă din partea mamei ei, caci bunica ei, Constanta, murise cu un an înainte.


Părinții mei au primit de la Taica Popa un cadou de nuntă neobișnuit, care cred că este simbolic pentru genul de persoana care a fost. 
Este o trilogie numita “Un om intre oameni”, carte și titlu care transmit, în opinia mea, si apartenență la o comunitate și unicitate. 
Este vorba despre un important personaj istoric romanesc, un revolutionar din 1848, Nicolae Bălcescu, al carui nume este dat multor institutii in Romania. 
Bălcescu era și pe cea mai mare bacnota din acea vreme cea de 100 de lei.
Mesajul lui Taica Popa  către părinții mei pe coperta interioară a cărții:” In drumul vietii ce-l veti parcurge impreuna, “Un om intre oameni” sa va fie pilda de munca si iubire de tara”.
Data dedicatiei este o luna dupa nunta iar locul e satul lui in Arges. Cred ca parintii mei l-au vizitat in sat dupa nunta, poate in luna de miere.

<figure>
<img src="./post7/book.png"
	style="display: block; margin: auto; width: 300px" />
</figure>
Cartea în sine este destul de dificila, o vastă (aproximativ 1800 de pagini) cronică balzaciana a societății românești din secolul al XIX-lea. 
Nu cred că părinții mei au citit-o vreodata, nu era genul lor de carte. 
Am citit-o eu pe la 20 de ani. Mi s-a părut interesanta, dar nu am iubit-o, așa cum am iubit alte carti, mai moderne ca stil, ale aceluiași autor, Camil Petrescu, un mare romancier si dramaturg român.
Tare îmi place acest obicei românesc de a scrie mesaje în cărțile oferite cadou. 
De fiecare dată când țin in mână cartea, prin această dedicație, simt că am în mână nu doar povestea lui Balcescu si scrisul lui Camil Petrescu dar si o legătura concreta cu toate generațiile care au venit înaintea mea, 
cu viața parintelui, a străbunicii,a  bunicii și părinților mei.

Taica Popa a fost el însuși „Un om printre oameni”.

`
		content = romText
	} else { 
const postText = `
# Father

In the Romanian villages of yesteryear there were two positions that were held in highest respect and had a big social influence. Those were the priest and the primary school teacher in the village (often there was only one teacher). 
Various Romanian novels from XIX and XXth centuries have at least some story arcs, if not the main one, about aspirations and tribulations  of poorer boys in their pursuit of these positions. 
It was a form of a social advancement, escaping the back breaking work of the land while still returning to the place of birth, usually to the delight of their parents, proud that their sons have climbed the social ladder. 
Unfortunately, it seems a little hard to imagine this respect today, especially for the teachers.

There were plenty of teachers in my extended family in the past but only one priest. He was called Florea (the male version of the name given to his sister, Floarea/Flora whose story is [here](./tablecloth) - their parents must have really liked flowers!). 
He was known as Father (in Romanian, taica popa) both in the family and in the wider community. 
He was the younger brother of my great-grandmother, Constance (her story is [here](./letters)). He cut an impressive figure. I met him as a child and I remember him as a tall man, still imposing, with a white beard and hair, kind blue eyes and a soft voice, but with an air of authority and deep knowledge, with everyone showing him great respect. He was about 80 at the time, obviously he looked to me as old as time, a lifetime of faith, suffering and wisdom etched on his face. He had it, whatever it might be: charisma, gift, calling or a magnetic presence.

For most of his life he was a priest in a village in the Arges county, both his and Constance’s birth place, a place about 50 km away from the village where Constance lived after her marriage and with her daughter Aurelia.

Constance, young war widow as she was, visited her brother as often as she could to ask for advice and get moral and practical support. This continued throughout her life. When my grandmother, Aurelia came back to the countryside to stay with her mother during the years of second world war, she worked with her uncle on various social, cultural and educational charitable projects. 
A few photos from that time survive.

<figure>
<img src="./post7/emptychair.jpg"
	style="display: block; margin: auto; width: 400px" />
</figure>

This one shows the Father seated with Aurelia standing behind the chair. I am intrigued by the empty chair. Did it mean anything? Someone absent?  Was it meant to show a certain social or moral hierarchy in the way and order that the people are standing? Why didn’t Aurelia sit next to him? Was it considered disrespectful because of his position? But then why not take the chair away and have the other people just stand behind him? Family and society customs locked away in the little photo but no one left that can explain this.

<figure>
<img src="./post7/mtg.png"
	style="display: block; margin: auto; width: 400px" />
</figure>
Another photo at one such event shows Father and Aurelia (on the right, a bit in the shade, wearing a traditional Romanian blouse).

Father had a very difficult time when the communists came into power given the combination of his family social and economical status and, of course, his faith and position.
As described in sources such as [this](https://en.wikipedia.org/wiki/Anti-Religious_Campaign_in_communist_Romania) 
the doctrine of communist atheism took a hostile stance against religion. 
There were plenty of Orthodox priests and higher ranking church officials that collaborated with the communist authorities but Father was among the 5,000 Romanian Orthodox Christian priests that were imprisoned. In 1962–1964, the party leadership approved a mass amnesty, extended to, among other prisoners, approximately 6,700 “guilty” of political crimes.

One of my favourite things about Father was that in 1969 he somehow (I suspect he asked for some favours from fellow priests) came to Bucharest to perform, with the parish priest, the marriage ceremony for  my parents.
<figure>
<img src="./post7/wedding.jpg"
	style="display: block; margin: auto; width: 400px" />
</figure>
This is  Father with my parents wearing the orthodox wedding crowns, bride and groom, the king and queen of the new family, with my achingly young father looking quite delighted and my mother quite solemn. It meant a lot for my mother to have Father there, the only relative from her mother side, her grandmother, Constance having died the year before.

My parents got an unusual wedding gift from Father which I think it’s quite symbolic of the man he was. It’s a trilogy whose title I’d translate like “ A Man Among People” or “One Among Many”, book and title conveying, in my opinion, both belonging to a community and uniqueness. It is about a major Romanian historical figure, a revolutionary from 1848, Nicolae Balcescu, whose name is given to many Romanian institutions. Balcescu was also on the highest denomination of the banknote at the time, 100 lei.
Father’s message to my parents on the inner cover of the book: “Throughout the life you’ll spend together, let  “One Among Many” be an example for you of striving, work and love for the country”. 
The date of the dedication is nearly a month after the wedding amd the location is his village  so they must have visited him that summer, possibly during their honeymoon.

<figure>
<img src="./post7/book.png"
	style="display: block; margin: auto; width: 500px" />
</figure>

The book itself is quite heavy going, a vast (about 1800 pages) Balzac-like chronicle of the Romanian society in the  XIX century. I don’t think my parents read it, at least not in its entirety. It wasn’t their sort of book. I read it when I was about 20. I found it interesting but I did not love it though I loved other works, more modern in style, by the same author, Camil Petrescu, who is a major Romanian novelist.  
I love this Romanian custom of writing messages in books given as gifts. 
Every time I pick the book I don't just have in my hand Balcescu's story written by Petrescu, but, through this dedication, I feel I had in my hand the connection to all the generations that come before me, to Father’s , my great-grandmother's, grandmother's and my parents’ lives.

Father himself was “A Man Among People”.

`
    content = postText
	}


const summary = Post7Summary(lang)
return {
	content,
	linkId: summary.linkId,
	title: summary.cardTitle
}
}

export default Post7;
