// BlogNav.js

import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Navbar, Nav, Form, FormControl, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types'
import InstagramIcon from '@mui/icons-material/Instagram';
const BlogNav = ({urlSearchParameters, lang, onLanguageSelected}) => {
	// compute href 
	return (
		<div>
			<Navbar style={{
				backgroundColor: "#D7E6FA",
				
			}}>
				<Navbar.Brand href="/" style={{ color: "black", marginLeft: "10px" }}>
					{lang==='rom'?'💎 Lada de zestre 💎':
					'💎 My Treasure Box 💎 '
					}
				</Navbar.Brand>
				<Navbar.Toggle />
				<Navbar.Collapse id="basic-navbar-nav" className="d-flex justify-content-end" style={{ marginRight: '20px' }}>
					<Nav>
						 <Nav.Link href="https://www.instagram.com/mytreasurebox_rom/" target='_blank' style={{color:"black"}}>
							Contact <InstagramIcon/>
						</Nav.Link>
						{/* <Nav.Link href="food" style={{color:"black"}}>
							Food 
						</Nav.Link>  */}
						<Nav.Link href="art" style={{color:"black"}}>
							My Art 🎨
						</Nav.Link>
						{/* <Nav.Link href="#contact" style={{color:"black"}}>
							Computer Network
						</Nav.Link> */}
					</Nav>
					<Dropdown  onClick={(e)=>{
						  e.preventDefault();
						  if (e.target.innerHTML.includes('English')) {
							onLanguageSelected('eng')
						  } else if (e.target.innerHTML.includes('Romanian')) {
							onLanguageSelected('rom')
						  }
					}}>
					<Dropdown.Toggle variant="success" id="dropdown-basic"
					
					>
						Language
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item href="eng">🇬🇧 English</Dropdown.Item>
						<Dropdown.Item href="rom">🇷🇴 Romanian</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				{/* <Form inline>
						<FormControl type="text" placeholder="Search" className="ml-auto" />
					</Form> */}
			</Navbar.Collapse>
		</Navbar>
		</div >
	)
}
BlogNav.displayName = 'Blog Navigation'
BlogNav.propTypes = {
	onLanguageSelected: PropTypes.func
}

export default BlogNav;
