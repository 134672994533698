// Post1.js

import Post1Summary from "../summary/Post1Summary"

const Post1 = (lang) => {
	let content = ''
    if (lang === 'rom') {
		const romText = `
Sunt Doina și postez aici despre lucruri pe care le iubesc, amintiri si  
povești despre stramosii mei si ce au lăsat ei în urmă. 
Sper că veți găsi ceva interesant pe aici. Site-ul e bilingv. Selectati limba pe care o preferati in dreapta sus.
Vă mulțumesc că ați citit.

##💥💥💥 Nou 💥💥💥: Am adaugat a [pagina noua](./art) care sa contina experimentele mele artistice.
`
		content = romText
	} else {

	const postText = `
I am Doina and I post here about things I love, my memories, stories of my Romanian ancestors and their legacies. 
This is mainly an ancestry blog but focused on stories rather than blood lines or timelines. 
I aim to be accurate as possible as I capture people, worlds or customs from the past.
The site is bilingual, select your preferred language from the dropdown in the top right. 
I hope you find something interesting here.
Thank you for reading.

##💥💥💥 New 💥💥💥: I added a [new page](./art) to host my various art experiments.
	`
	content = postText
	}
	const summary = Post1Summary(lang)
	return {
		content,
		linkId: summary.linkId,
		title: summary.cardTitle
	}
 }
export default Post1;
