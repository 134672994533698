// PostsSummary.js

import React from "react";
// import Post1Summary from "./summary/Post1Summary";
import Post2Summary from "./summary/Post2Summary";
import PostSummary from "./summary/PostSummary";
import Post3Summary from "./summary/Post3Summary";
import Post4Summary from "./summary/Post4Summary";
import Post5Summary from "./summary/Post5Summary";
import Post6Summary from "./summary/Post6Summary";
import Post7Summary from "./summary/Post7Summary";
import Post8Summary from "./summary/Post8Summary";
import Post9Summary from "./summary/Post9Summary";
import Post10Summary from "./summary/Post10Summary";
import Post11Summary from "./summary/Post11Summary";
import { Container, Row, Col, Card } from 'react-bootstrap';


const PostsSummary = ({ lang, likes, comments, onLikesUpdated, onCommentsUpdated }) => {
	return (
		<Container>
			<Row className="justify-content-between">
			<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post11Summary(lang).cardTitle}
						cardText={Post11Summary(lang).cardText}
						photoPathLong={Post11Summary(lang).photoPath}
						photoPathShort={Post11Summary(lang).photoPathShort}
						linkId={Post11Summary(lang).linkId}
						likes={likes[Post11Summary(lang).linkId]}
						comments={comments[Post11Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}

					/>
				</Col>
				<Col md={3} className="mb-4 float-right">
					<Card>
						<Card.Body>
							<Card.Title>{lang === 'eng' ? 'Featured' : 'Cele mai citite'}</Card.Title>
							<ul className="list-unstyled">
								<li><a href={`bookswar?lang=${lang}`}>{lang === 'eng' ? 'Books, school and WW1' : 'Cărți, școală și primul război mondial'}</a></li>
								<li><a href={`letters?lang=${lang}`}>{lang === 'eng' ? 'Letters, lives and love' : 'Scrisori de demult'}</a></li>
								<li><a href={`tablecloth?lang=${lang}`}>{lang === 'eng' ? 'The tablecloth' : 'Fața de masă'}</a></li>

							</ul>
						</Card.Body>
					</Card>
					<Card>
						<Card.Body>

							<Card.Title>{lang === 'eng' ? 'Recent Posts Summary' : 'Postari recente'}</Card.Title>
							<ul className="list-unstyled">
							   <li><a href={`swearing?lang=${lang}`}>{lang === 'eng' ? 'Brave Swearing' : 'Injuraturi curajoase'}</a></li>					
								<li><a href={`excellence?lang=${lang}`}>{lang === 'eng' ? 'Pursuit of excellence' : 'Vocatie'}</a></li>
								<li><a href={`fringes?lang=${lang}`}>{lang === 'eng' ? 'On the fringes' : 'Pe marginea istoriei'}</a></li>
								{/* <li><a href={`look?lang=${lang}`}>{lang === 'eng' ? 'Something to look at' : 'Ceva de privit'}</a></li>
								<li><a href={`father?lang=${lang}`}>{lang === 'eng' ? 'Father' : 'Taica Popa, "Un om intre oameni"'}</a></li>
								<li><a href={`metaphor?lang=${lang}`}>{lang === 'eng' ? 'The accidental metaphor' : 'Metafora din greseala'}</a></li>
								<li><a href={`battles?lang=${lang}`}>{lang === 'eng' ? 'Epic battles in the kitchen' : 'Bătăliile eroice din bucătarie'}</a></li>
								<li><a href={`tablecloth?lang=${lang}`}>{lang === 'eng' ? 'The tablecloth' : 'Fața de masă'}</a></li>
								<li><a href={`letters?lang=${lang}`}>{lang === 'eng' ? 'Letters, lives and love' : 'Scrisori de demult'}</a></li>
								<li><a href={`bookswar?lang=${lang}`}>{lang === 'eng' ? 'Books, school and WW1' : 'Cărți, școală și primul război mondial'}</a></li> */}

							</ul>
						</Card.Body>
					</Card>
				</Col>
				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post10Summary(lang).cardTitle}
						cardText={Post10Summary(lang).cardText}
						photoPathLong={Post10Summary(lang).photoPath}
						photoPathShort={Post10Summary(lang).photoPathShort}
						linkId={Post10Summary(lang).linkId}
						likes={likes[Post10Summary(lang).linkId]}
						comments={comments[Post10Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}

					/>
				</Col>


				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post9Summary(lang).cardTitle}
						cardText={Post9Summary(lang).cardText}
						photoPathLong={Post9Summary(lang).photoPath}
						photoPathShort={Post9Summary(lang).photoPathShort}
						linkId={Post9Summary(lang).linkId}
						likes={likes[Post9Summary(lang).linkId]}
						comments={comments[Post9Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}

					/>
				</Col>
				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post8Summary(lang).cardTitle}
						cardText={Post8Summary(lang).cardText}
						photoPathLong={Post8Summary(lang).photoPath}
						photoPathShort={Post8Summary(lang).photoPathShort}
						linkId={Post8Summary(lang).linkId}
						likes={likes[Post8Summary(lang).linkId]}
						comments={comments[Post8Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}

					/>
				</Col>
				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post7Summary(lang).cardTitle}
						cardText={Post7Summary(lang).cardText}
						photoPathLong={Post7Summary(lang).photoPath}
						photoPathShort={Post7Summary(lang).photoPathShort}
						linkId={Post7Summary(lang).linkId}
						likes={likes[Post7Summary(lang).linkId]}
						comments={comments[Post7Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}

					/>
				</Col>
				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post6Summary(lang).cardTitle}
						cardText={Post6Summary(lang).cardText}
						photoPathLong={Post6Summary(lang).photoPath}
						photoPathShort={Post6Summary(lang).photoPathShort}
						linkId={Post6Summary(lang).linkId}
						likes={likes[Post6Summary(lang).linkId]}
						comments={comments[Post6Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}

					/>
				</Col>
				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post5Summary(lang).cardTitle}
						cardText={Post5Summary(lang).cardText}
						photoPathLong={Post5Summary(lang).photoPath}
						photoPathShort={Post5Summary(lang).photoPathShort}
						linkId={Post5Summary(lang).linkId}
						likes={likes[Post5Summary(lang).linkId]}
						comments={comments[Post5Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}

					/>
				</Col>
				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post4Summary(lang).cardTitle}
						cardText={Post4Summary(lang).cardText}
						photoPathLong={Post4Summary(lang).photoPath}
						photoPathShort={Post4Summary(lang).photoPathShort}
						linkId={Post4Summary(lang).linkId}
						likes={likes[Post4Summary(lang).linkId]}
						comments={comments[Post4Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}
					/>
				</Col>
				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post3Summary(lang).cardTitle}
						cardText={Post3Summary(lang).cardText}
						photoPathLong={Post3Summary(lang).photoPath}
						photoPathShort={Post3Summary(lang).photoPathShort}
						linkId={Post3Summary(lang).linkId}
						likes={likes[Post3Summary(lang).linkId]}
						comments={comments[Post3Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}
					/>
				</Col>
				<Col md={9} className="mb-4">
					<PostSummary
						lang={lang}
						cardTitle={Post2Summary(lang).cardTitle}
						cardText={Post2Summary(lang).cardText}
						photoPathLong={Post2Summary(lang).photoPath}
						photoPathShort={Post2Summary(lang).photoPathShort}
						linkId={Post2Summary(lang).linkId}
						likes={likes[Post2Summary(lang).linkId]}
						comments={comments[Post2Summary(lang).linkId] || []}
						onLikesUpdated={onLikesUpdated}
						onCommentsUpdated={onCommentsUpdated}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default PostsSummary;
