// Post2.js

const Post2Summary = (lang) => {
     const isRom = lang === 'rom'
	const cardTextEng = `
     This is the story of my great-grandfather, Pârvu, his schooling at the beginning of the XXth century, the textbooks he left behind and his tragic death in the first world war. 
	`
	const cardTextRom = `
	Asta e povestea strabunicului meu, Pârvu. A lasat in urma citeva lucruri deosebite inainte de a a muri eroic in timpul Primului Razboi Mondial.
	`
	
	return {
		lang,
		cardTitle: isRom? 'Carti, scoala si razboi': 'Books, school and war', 
		cardText:  isRom ?  cardTextRom: cardTextEng, 
		photoPath: '/post2/collage2long.png', 
        photoPathShort: '/post2/collage2s.png', 
		linkId: 'bookswar'
	}
 }

export default Post2Summary;
