// App.js

import React, { useState, useEffect } from "react";
import "./App.css";
import { Amplify, API } from 'aws-amplify';
import PostsSummary from "./components/PostsSummary";
import Navbar from "./components/BlogNav"
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from "react-router-dom";
import { Routes, Route } from 'react-router-dom'
import Post1 from "./components/posts/Post1";
import Post2 from "./components/posts/Post2";
import Post3 from "./components/posts/Post3";
import Post4 from "./components/posts/Post4";
import Post5 from "./components/posts/Post5";
import Post6 from "./components/posts/Post6";
import Post7 from "./components/posts/Post7";
import Post8 from "./components/posts/Post8";
import Post9 from "./components/posts/Post9";
import Post10 from "./components/posts/Post10";
import Post11 from "./components/posts/Post11";
import PostPage from "./components/posts/PostPage";
import RecipesPage from "./components/food/RecipesPage";
import ArtPage from "./components/art/ArtPage";
import { Container } from 'react-bootstrap'
import { Link, Stack, Typography } from '@mui/material';
import Markdown from './components/Markdown';
// import { generateClient } from "aws-amplify/api";
// import {
//     withAuthenticator,
//   } from "@aws-amplify/ui-react";
const apiName = 'TreasureBoxAPI';

const App = () => {
    const [language, setLanguage] = useState('eng');
    const [comments, setComments] = useState({});
    const [likes, setLikes] = useState({});

    Amplify.configure({
        API: {
            endpoints: [
                {
                    name: apiName,
                    endpoint: 'https://3vjzm81mh8.execute-api.eu-west-1.amazonaws.com/dev'
                }
            ]
        }
    });
    // const client = generateClient();

    const history = useNavigate()
    const [urlSearchParameters, setUrlSearchParameters] = useSearchParams();
    const urlLang = urlSearchParameters.get('lang')
    const next = language === 'rom' ? 'Inainte: ' : 'Next: '

    // build data 
    const post1 = Post1(language)
    const post2 = Post2(language)
    const post3 = Post3(language)
    const post4 = Post4(language)
    const post5 = Post5(language)
    const post6 = Post6(language)
    const post7 = Post7(language)
    const post8 = Post8(language)
    const post9 = Post9(language)
    const post10 = Post10(language)
    const post11 = Post11(language)


    // post1.rightLinkId = post11.linkId
    // post1.rightLinkText = post11.title
    post11.leftLinkId = `/?lang={language}`
    post11.leftLinkText = language === 'rom' ? 'Pagina principala' : 'Back to main page'
    post11.rightLinkId = post10.linkId
    post11.rightLinkText = post10.title


    post10.leftLinkId = post11.linkId
    post10.leftLinkText = post11.title
    post10.rightLinkId = post9.linkId
    post10.rightLinkText = post9.title

    post9.leftLinkId = post10.linkId
    post9.leftLinkText = post10.title
    post9.rightLinkId = post8.linkId
    post9.rightLinkText = post8.title



    post8.leftLinkId = post9.linkId
    post8.leftLinkText = post9.title
    post8.rightLinkId = post7.linkId
    post8.rightLinkText = post7.title

    post7.leftLinkId = post8.linkId
    post7.leftLinkText = post8.title
    post7.rightLinkId = post6.linkId
    post7.rightLinkText = post6.title

    post6.leftLinkId = post7.linkId
    post6.leftLinkText = post7.title
    post6.rightLinkId = post5.linkId
    post6.rightLinkText = post5.title

    post5.leftLinkId = post6.linkId
    post5.leftLinkText = post6.title
    post5.rightLinkId = post4.linkId
    post5.rightLinkText = post4.title


    post4.leftLinkId = post5.linkId
    post4.leftLinkText = post5.title
    post4.rightLinkId = post3.linkId
    post4.rightLinkText = post3.title

    post3.leftLinkId = post4.linkId
    post3.leftLinkText = post4.title
    post3.rightLinkId = post2.linkId
    post3.rightLinkText = post2.title

    post2.leftLinkId = post3.linkId
    post2.leftLinkText = post3.title


    const onLanguageSelectedFc = (lang) => {
        if (urlSearchParameters.get('lang')) {
            urlSearchParameters.delete(lang)
        }
        setLanguage(lang)
        history(`?lang=${lang}`)
    }
    async function fetchCommentsAndLikes() {
        try {
            const commentsApi = await API.get(apiName, '/comments/all')
            setComments(commentsApi)
            const likes = await API.get(apiName, '/likes/all')
            let likesModified = {}
            Object.keys(likes).map((likeKey) => {
                const likeObj = likes[likeKey]
                if (likeObj.likes) {
                    const counter = likeObj.likes.reduce((counter, liked) => {
                        if (liked) {
                            counter += 1
                        }
                        else { counter -= 1 }
                        return counter;
                    }, 0);
                    likesModified[likeKey] = Math.max(counter, 0)
                }
            })
            setLikes(likesModified)
        } catch (e) {
            console.error(e)
        }
    }
    useEffect(() => {
        if (urlLang === 'rom') {
            setLanguage(urlLang)
        } else {
            setLanguage('eng')
        }

        fetchCommentsAndLikes()


    }, [])

    return (
        <Routes>
            <Route
                path='/' element={

                    <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                        <Navbar
                            lang={language}
                            onLanguageSelected={onLanguageSelectedFc} />
                        <Stack direction="column" justifyContent="flexCenter"
                            alignItems="center" spacing={10}>
                            <Typography variant='h2' sx={{paddingLeft: '10px', paddingRight: '10px'}}> {language === 'eng' ? 'My Treasure Box' : '💎 Lada de zestre'}</Typography>
                            <Typography variant='h3' sx={{paddingLeft: '10px', paddingRight: '10px'}}>  {language === 'eng' ? 'Stories from my Romanian family' :
                                'Comori din familia mea'}</Typography>
                        </Stack>
                        <Container>
                            <>
                                <br />
                                <a id='intro' sx={{ paddingTop: '20px' }}> </a>
                                <a id='intro?lang=eng' sx={{ paddingTop: '20px' }}> </a>
                                <a id='intro?lang=rom' sx={{ paddingTop: '20px' }}> </a>
                                <Markdown className="markdown" sx={{ display: 'relative', marginTop: '20px' }} key='intro' >
                                    {post1.content}
                                </Markdown>


                            </>
                        </Container>
                        <PostsSummary
                            lang={language}
                            likes={likes}
                            comments={comments}
                            onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                            onCommentsUpdated={(newComments) => { setComments(newComments) }}
                        />
                    </div>
                }
            />
            <Route
                path={`/bookswar`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage
                                lang={language}
                                linkId={post2.linkId}
                                text={post2.content}
                                leftLinkId={post2.leftLinkId}
                                leftLinkText={post2.leftLinkText}
                                rightLinkId={post2.rightLinkId}
                                rightLinkText={post2.rightLinkText}
                                likes={likes[post2.linkId]}
                                comments={comments[post2.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/letters`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar
                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage linkId={post3.linkId}
                                lang={language}
                                text={post3.content}
                                leftLinkId={post3.leftLinkId}
                                leftLinkText={post3.leftLinkText}
                                rightLinkId={post3.rightLinkId}
                                rightLinkText={post3.rightLinkText}
                                likes={likes[post3.linkId]}
                                comments={comments[post3.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/tablecloth`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={(lang) => {
                                    if (urlSearchParameters.get('lang')) {
                                        urlSearchParameters.delete(lang)
                                    }
                                    setLanguage(lang)
                                    history(`?lang=${lang}`)
                                }} />
                            <PostPage linkId={post4.linkId}
                                lang={language}
                                text={post4.content}
                                leftLinkId={post4.leftLinkId}
                                leftLinkText={post4.leftLinkText}
                                rightLinkId={post4.rightLinkId}
                                rightLinkText={post4.rightLinkText}
                                likes={likes[post4.linkId]}
                                comments={comments[post4.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}

                            />
                        </div>
                    </>
                } />
            <Route
                path={`/battles`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage linkId={post5.linkId}
                                lang={language}
                                text={post5.content}
                                leftLinkId={post5.leftLinkId}
                                leftLinkText={post5.leftLinkText}
                                rightLinkId={post5.rightLinkId}
                                rightLinkText={post5.rightLinkText}
                                likes={likes[post5.linkId]}
                                comments={comments[post5.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/metaphor`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage linkId={post6.linkId}
                                lang={language}
                                text={post6.content}
                                leftLinkId={post6.leftLinkId}
                                leftLinkText={post6.leftLinkText}
                                rightLinkId={post6.rightLinkId}
                                rightLinkText={post6.rightLinkText}
                                likes={likes[post6.linkId]}
                                comments={comments[post6.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/father`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage linkId={post7.linkId}
                                lang={language}
                                text={post7.content}
                                leftLinkId={post7.leftLinkId}
                                leftLinkText={post7.leftLinkText}
                                rightLinkId={post7.rightLinkId}
                                rightLinkText={post7.rightLinkText}
                                likes={likes[post7.linkId]}
                                comments={comments[post7.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/look`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage linkId={post8.linkId}
                                lang={language}
                                text={post8.content}
                                leftLinkId={post8.leftLinkId}
                                leftLinkText={post8.leftLinkText}
                                rightLinkId={post8.rightLinkId}
                                rightLinkText={post8.rightLinkText}
                                likes={likes[post8.linkId]}
                                comments={comments[post8.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/fringe`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage linkId={post9.linkId}
                                lang={language}
                                text={post9.content}
                                leftLinkId={post9.leftLinkId}
                                leftLinkText={post9.leftLinkText}
                                rightLinkId={post9.rightLinkId}
                                rightLinkText={post9.rightLinkText}
                                likes={likes[post9.linkId]}
                                comments={comments[post9.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/excellence`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage linkId={post10.linkId}
                                lang={language}
                                text={post10.content}
                                leftLinkId={post10.leftLinkId}
                                leftLinkText={post10.leftLinkText}
                                rightLinkId={post10.rightLinkId}
                                rightLinkText={post10.rightLinkText}
                                likes={likes[post10.linkId]}
                                comments={comments[post10.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/swearing`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={onLanguageSelectedFc} />
                            <PostPage linkId={post11.linkId}
                                lang={language}
                                text={post11.content}
                                leftLinkId={post11.leftLinkId}
                                leftLinkText={post11.leftLinkText}
                                rightLinkId={post11.rightLinkId}
                                rightLinkText={post11.rightLinkText}
                                likes={likes[post11.linkId]}
                                comments={comments[post11.linkId]}
                                onLikesUpdated={(newLikes) => { setLikes(newLikes) }}
                                onCommentsUpdated={(newComments) => { setComments(newComments) }}
                            />
                        </div>
                    </>
                } />
            <Route
                path={`/food`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite" }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={(lang) => {
                                    if (urlSearchParameters.get('lang')) {
                                        urlSearchParameters.delete(lang)
                                    }
                                    setLanguage(lang)
                                    history(`?lang=${lang}`)
                                }} />
                            <RecipesPage linkId={post4.linkId}
                                lang={language}
                                text={post4.content}
                                leftLinkId={post4.leftLinkId}
                                leftLinkText={post4.leftLinkText}
                                rightLinkId={post4.rightLinkId}
                                rightLinkText={post4.rightLinkText}


                            />
                        </div>
                    </>
                } />
            <Route
                path={`/art`}
                element={
                    <>
                        <div className="main-container" style={{ backgroundColor: "antiquewhite",height:"100%", minHeight: '1500px' }}>
                            <Navbar

                                lang={language}
                                onLanguageSelected={(lang) => {
                                    if (urlSearchParameters.get('lang')) {
                                        urlSearchParameters.delete(lang)
                                    }
                                    setLanguage(lang)
                                    history(`?lang=${lang}`)
                                }} />
                            <ArtPage linkId={post4.linkId}
                                lang={language}
                                text={post4.content}
                                leftLinkId={post4.leftLinkId}
                                leftLinkText={post4.leftLinkText}
                                rightLinkId={post4.rightLinkId}
                                rightLinkText={post4.rightLinkText}


                            />
                        </div>
                    </>
                } />
        </Routes>
    );
};

export default App;