const Post11Summary = (lang) => {
	const isRom = lang === 'rom'

	
	return {
		lang,
		cardTitle: isRom?  'Înjuraturi curajoase' :'Brave swearing',	
		cardText:  isRom ?  'Acesta este o poveste despre bunicul meu patern, anii la canal si cum i-a înjurat in fațǎ pe niste comunisti oficiali': 
		'This is the story of my paternal grandad, his years in the communist labor camp and how he sweared at the party officials and got away with it', 
		photoPath: '/post11/collage11l.png', 
		photoPathShort: '/post11/collage11s.png', 
		linkId: 'swearing'
	}

}

export default Post11Summary;
