// Post2.js
import Post2Summary from "../summary/Post2Summary"


const Post2 = (lang) => {
	let content = ''
    if (lang === 'rom') {
     const romText = `
# Cărți, școală și război la inceput de secol XX

Detaliile principale ale vieții străbunicului meu, Pârvu, sunt simple de povestit: s-a născut în anii 1880 (calculind dupa anii de școală, cred în jurul anului 1885) într-o familie de proprietari de pământ, relativ înstărită din Argeș. 
A studiat la Scoala Normala, s-a casatorit cu o femeie pe nume Constanța (povestea ei [aici](./letters)). 
Cum era obiceiul, soția s-a mutat în satul soțului. A fost învățator cîțiva ani si apoi a fost chemat la oaste. 
Sotia i-a născut o fiică după ce Pârvu a fost ucis în Primul Război Mondial în 1917.
Două fotografii cu el au supraviețuit, numele lui în marmură pe un monument de război, câteva caiete de școală și câteva cărți. 
Cu toată viața tragic de scurtă, el a lăsat, cred, o moștenire importanta.
<figure>
<img src="./post2/portrait.png"
          style="display: block; margin: auto; width: 200px" />
          <figcaption style="text-align: center;" ><i>Pârvu ca soldat</i></figcaption>
</figure>

## Fotografia
Cealaltă fotografie a lui Pârvu este mai interesantă. El e în stânga iar soția sa, Constanța, este pe scaun. Bărbatul din dreapta este tatăl lui Pârvu. Există o poveste de familie despre această ocazie. Deși nu am putut confirma din altă sursă, cred că elemente din fotografie confirmă povestea.
Fotografia trebuie sa fi fost facuta pe la 1910 si ceva, evident inainte de 1917.
<figure>
<img src="./post2/family.png"
          style="display: block; margin: auto; width: 400px" />
          <figcaption style="text-align: center;" ><i>Pârvu, soția și tatăl lui și preotul local (deci străbunicii mei și un stră-străbunic)</i></figcaption>
</figure>
Se zice că familia lui ar fi donat ceva școlii din sat. Exact ce au donat  nu am putut afla, dar se zice că această fotografie a fost făcută la inaugurarea daniei lor. Pare o ocazie festivă: sunt ghirlande de flori în jurul intrării și mai multe flori pe trepte. Pe trepte sunt si ceea ce arată ca o cărămidă și o carte (simboluri ale donației, poate?). 
Este greu de imaginat că au adus un fotograf, au pozat dar au uitat să mature treptele, așa că trebuie să fi fost intenționat. 
Un preot este adesea prezent la o deschidere pentru a oferi binecuvântări, așa că aceasta ar explica a patra persoană din fotografie. 
Constanța este îmbrăcată în costum popular, iarași normal pentru o ocazie festivă. 
În această fotografie și Pârvu pare ca ar avea un costum tradițional, deși ceasul de buzunar este poate mai atipic.


## Primul Război Mondial
Nu există detalii specifice despre activitatea lui Pârvu sau scrisori din timpul războiului. Tot ce avem este numele sau și gradul de locotenent, scris primul pe acest monument. Memorialul a fost construit în perioada interbelică si se află în fața școlii din satul natal.
Si cuvintele astea sapate in marmura "Comuna Harsesti recunoscatoare Vitejilor consateni cazuti in rasboiul  dela 1916-1918 pentru intregirea neamului românesc" 
<figure>
<img src="./post2/memorial.png"
          style="display: block; margin: auto; width: 400px" />
          <figcaption style="text-align: center;" ><i>Credit foto <a href='https://marelerazboi-arges.ro/item/harsesti-monumentul-eroilor-cazuti-in-primul-razboi-mondial' target='blank'>aici</a> </i></figcaption>
</figure>

### Câteva fapte generice despre Primul Război Mondial (în principal din Wikipedia):
România a fost neutră în primii doi ani ai Primului Război Mondial, intrând de partea Puterilor Aliate în august 1916. 
Dupa bătălii pe văile Oltul și Jiul, Puterile Centrale au ocupat Bucureștiul, guvernul și familia regală s-au mutat la Iași. 
Ofensiva germană a fost oprită în urma mai multor victorii defensive în august 1917 la Mărăști, Mărășești și Oituz, bătălii care au avut loc sub sloganul „Pe aici nu se trece”. 
Intr-una din aceste batalii a murit si Pârvu.
<figure>
<img src="./post2/map.png"
          style="display: block; margin: auto; width: 400px" />
          <figcaption style="text-align: center;" ><i>România înainte și după primul război mondial (foto credit: wikipedia)</i></figcaption>
</figure>
<br>

##Scoala
Nu stiu exact la ce Școala Normală a studiat Pârvu, dar dovezile istorice indica o anumita scoala. <br>
Semnăturile colegilor săi (mai multe despre asta mai târziu) arată că proveneau din diferite zone din sudul României, precum Olt, Muscel si Dîmbovita. 
Cred că am găsit școala pe care a urmat-o.
Potrivit acestei  [surse](https://adevarul.ro/stiri-locale/pitesti/de-la-bucuresti-la-campulung-povestea-primei-2150498.html)  prima Școală Normală din sudul țării a fost în Câmpulung, înființată la sfirsit de secol XIX, azi numita Colegiul National Pedagogic ”Carol I". 
Iată politica sa initiala de selecție: 
_„Tânărul selectat pentru a deveni invatator trebuia să fie fiu de ţăran născut şi crescut în mediul rural, să nu fie mai mic de 16 ani sau mai mare de 18 ani, să fie absolvent a patru clase primare cu notă eminentă, să aibă o sănătate deplină[...] Candidatul selecţionat trebuia să aducă o declaraţie din partea tatălui prin care acesta se angaja ca fiul său să urmeze cursurile şcolii până la absolvire, iar după absolvire să rămână cel puţin şase ani la postul unde a fost repartizat”_.
Mi se pare extrem de bine gîndit acest sistem.

Criteriile de admitere s-ar fi putut schimba de-a lungul timpului, elevii din fotografia de mai jos par mai mici de 16 ani. 
Școala trebuie să fi fost internat, având în vedere răspândirea geografică a elevilor săi.
<figure>
<img src="./post2/schoolphotos.png"
          style="display: block; margin: auto; width: 400px" />
          <figcaption style="text-align: center;" ><i>Fotografii din sursa citată, făcute în 1910, la 4 ani după ultima dată pe care o găsesc în notițele școlare ale lui Pârvu, arată elevi de diferite vârste și o clasă de educație fizică interesantă! </i></figcaption>
</figure>

## Cărțile
### Chimie
Înapoi la dovezi mai personale. 
De vreo 10 ani, am avut notitele de chimie ale lui Pârvu. 
Ele descriu metale comune, zinc, crom, nichel și diverse combinații, carbonatul acesta, sulfura cealalta, sulfida de asta, caracteristicile lor, procesul de fabricare a oglinzilor și multe altele. 
Mi-au plăcut caligrafia frumoasă, hârtia mătăsoasă, dăruirea lui evidentă pentru studii, semnăturile și datele . 
Mi-a plăcut enorm faptul că singura lui nepoată, mama mea, a devenit ulterior profesoară de chimie.
<figure>
<img src="./post2/chemistrynotes.png"
          style="display: block; margin: auto; width: 400px" />
          <figcaption style="text-align: center;" ><i>Notele de chimie ale lui Pârvu</i></figcaption>
</figure>
Recent, am găsit printre cartile familiei, sursa acestor note: o uimitoare carte franceză de chimie, apărută la Librarie Hachette în 1896, elegantă și într-o stare foarte bună. 
La acea vreme, franceza era limba românilor educați, așa că probabil că o cunoștea.
<figure>
<img src="./post2/chemistrycover.png"
          style="display: block; margin: auto; width: 400px" />
          <figcaption style="text-align: center;" ><i>Copertă și pagina interioară a cărții de chimie și prima pagină a cărții</i></figcaption>
</figure>
Alte pagini din carte:
<figure>
<img src="./post2/chemistryinside.png"
          style="display: block; margin: auto; width: 400px" />
          <figcaption style="text-align: center;" ><i>Mai multe pagini ale cărții franceze, o mulțime de ilustrații și explicații ale diferitelor procese de la fabricarea oțelului, uleiului, alcoolului, zahărului și multe altele.
          </i></figcaption>
</figure>
Mi s-a părut interesant chiar și vechiul semn de carte, Parsival/ Parzival(?). 
Nu am putut găsi prea multe despre ce ar putea fi asta.
<img src="./post2/chemistrybookmark.png"
     style="display: block; margin: auto; width: 400px" />


Baietii mei au avut ideea să caute tabelul periodic pentru a afla câte elemente chimice erau cunoscute atunci. 
Spre surprinderea noastră, nu am gasit nici un tabel periodic!

Am căutat ​​și am găsit 1869 ca fiind anul descoperirii lui Medeelev, dar se pare că până în 1896 nu era încă în manualul de chimie al unei edituri franceze de prestigiu.
Existența acestei cărți mă face curioasa. De ce ar trebui să-și ia o carte de chimie franceză? 
Pare prea avansata pentru o scoala de învațatori. Oare pentru că nu existau cărți românești de chimie la vremea aceea? 
Avea un interes deosebit pentru chimie? Cum a obtinuit cartea? 
A comandat-o cumva? O librărie din București sau Pitești? 
Existau în mod obișnuit manuale de stiinta in franceză la acea vreme in Romania? Întrebări fără răspuns.

### Geometrie
Cealaltă carte a lui Pârvu pe care am găsit-o este o carte de geometrie. 
Aceasta a fost întotdeauna materia mea preferată și am crezut că „am mostenit” interesul si talentul pentru ea de la tatăl meu, profesor de matematică. 
Se pare că există alte conexiuni cu geometrie în familia mea.


Manualul de geometrie este foarte diferit. Pare tăiat si legat manual, cu însemnări frecvente ale lui Pârvu, de genul „Am ajuns până aici în martie 1903” sau „Până aici în anul II” și diverse comentarii si note. 
Din notițe, cred că manualul a fost folosit mai mulți ani de școală. Unele dintre notitele sale de studiu sunt legate în carte.
Cred că manualul francez trebuie să fi fost scump, așa că l-a păstrat curat, cu notite pe hârtii separate, în timp ce cartea de geometrie este mai degrabă o carte de lucru mai ieftina, 
anotata, semnata, mâzgălita, uzata, mult mai personală.
Cartea de geometrie este în limba română și este din 1899 (cumva ediția a X-a pe atunci!).
<figure>
<img src="./post2/geometryinside.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i> Coperta interioară și cuprins
     </i></figcaption>
</figure>

Câteva probleme familiare:
<img src="./post2/geometryproblems.png"
     style="display: block; margin: auto; width: 400px" />
În timp ce conceptele nu s-au schimbat de mii de ani, limbajul este foarte interesant. 
Sunt litere care nu mai există ca e, u și i cu un ˇ deasupra, mult limbaj arhaic si latinizat.
Iata citeva exemple la intimplare:
Teorema lui Pitagora :

_Patratul facut pe ipotenusa unui triunghiu dreptunghiu este echivalent cu suma patratelor facute pe celelalte doua laturi._

Sau

_Intr’un triunghiu, oare-care, din doua laturi neegale, cea mai mare este aceea care se opune la un unghiu mai mare si reciprocamente din doua unghiuri neegale, cel mai mare este acela care se opune la o latura mai mare._

Sau

_Intr’o circonferinta data, se inscrie un pentagon stelat._

Sau

_Dreptele care unesc mijlocul laturilor unui triunghiu isoscel cu crestetele opuse trec prin acelasi punt._

Sau

_Se numeste proectiunea unui punt pe plan piciorul perpendicularei scoborite din puntul dat pe plan._

Sau

_Proectiunea unei linii pe un plan este linia care unesce proectiunile tuturor punturilor dreptei date pe plan._
     
<figure>
<img src="./post2/geometrynotes.png"
     style="display: block; margin: auto; width: 400px" />
<figcaption style="text-align: center;" ><i> Notele lui Pârvu legate în carte cu aceeași frumoasă caligrafie
</i></figcaption>
</figure>
Un fel de notificare legală (dreptul de autor?) 

_Copiile nesemnate vor fi urmărite conform legii_
<img src="./post2/copyright.png"
     style="display: block; margin: auto; width: 400px" />
Probabil cea mai mare surpriză a cărții de geometrie este că mai mulți dintre colegii săi au semnat cartea de obicei cu titlul „Suvenir” și 
dându-și numele, uneori adresa sub formă de sat și județ. 
Seamana cu un “oracol” de la 1900! 
O dedicație memorabilă, o poezie frumos caligrafiată (un scris chiar mai frumos decât străbunicul meu!) din 1902, semnată de un coleg, 
Ionita C Ion, spune așa ceva (deși o virgula in plus ar fi mers):

_Multe uita omul in lume, <br>
Multe am uitat si eu, <br>
Dar pe tine nu se pote <br>
Sa te uit amicul meu_<br>
     Sper ca ai avut o viata buna, Ionita C Ion.
<figure>
     <img src="./post2/signatures.png"
               style="display: block; margin: auto; width: 400px" />
               <figcaption style="text-align: center;" ><i> Semnături și adrese de acasă de la colegii din cartea de geometrie a lui Pârvu
               </i></figcaption>
     </figure>
Incerc emotii puternice de fiecare dată când țin in mînă aceste cărți și note: timpul, istoria, respectul pentru educație, sacrificiul de sine.<br>
Odihnește-te în pace, străbunicule!
`
    content = romText
    } else {
     const engText = `
# Books, School and War 📚 


The basic facts of my great-grandfather's, Pârvu, life are simple: he was born in the 1880s (working back from his school years, I believe around 1885) into a relatively well-off, land-owning family in Arges County. He studied at Scoala Normala (teacher training college), married a woman called Constanta (her story [here](./#letters)). As was the custom, the wife moved to the husband’s village. He fathered a daughter that was born after he was killed in World War 1 in 1917.
Two photos of him survive, his name in marble on a war monument, a few school notes and a few books. For all the tragically short life, he left, I think, a worthy legacy.
<figure>
<img src="./post2/portrait.png"
     style="display: block; margin: auto; width: 200px" />
     <figcaption style="text-align: center;" ><i>Pârvu as a soldier</i></figcaption>
</figure>

## The photo
The other photo of Pârvu is more interesting. It shows him on the left and his wife, Constanta seated. The man on the right is his father. There is a family story about the occasion. While I could not confirm it from another source, I think there are elements on the photos that are consistent with the story.
<figure>
<img src="./post2/family.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>Pârvu, his wife and father and the local priest (so my great- grandparents and a great-great-grandfather)</i></figcaption>
</figure>
The story is that his family were benefactors of the local school. Exactly what they paid for(construction, repairs, extension, fittings or study materials) I could not find out but the family story is that this photo was taken at the inauguration of their donation (in Romanian danie). It looks like a festive occasion: there are flower chains around the entrance and more flowers on the steps. Also on the steps are what looks like a brick and a book( symbols of the donation, maybe? ). It is hard to imagine that they brought a photographer, posed but forgot to swipe the steps, so it must be deliberate. A priest is often present at an opening to give blessings so this would explain the fourth person in the photo. Constanta is dressed in the folk costume, which would be normal for a festive occasion, In this photo, Pârvu too looks like he is wearing a traditional costume though the pocket watch is perhaps less traditional.


## The first world war
There are no specific details of his activity or letters during the war. All we have is his name and rank, a locotenent, at the top of this monument. The memorial is located in front of the school in his village and expresses the locals' gratitude.
<figure>
<img src="./post2/memorial.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>Photo credit <a href='https://marelerazboi-arges.ro/item/harsesti-monumentul-eroilor-cazuti-in-primul-razboi-mondial' target='blank'>here</a></i></figcaption>
</figure>

### Some generic WW1 facts (mainly from Wikipedia):
Romania was neutral for the first two years of WWI, entering on the side of the Allied Powers in August 1916. It had the most significant oil fields in Europe.
Romanian forces were involved in heavy battles on Oltul and Jiul valleys against advancing German forces. The Central Powers occupied Bucharest while the government and royal family moved to Iasi in Moldova, east Romania. The German offensive to conquer this last bit of Romania was stopped following  several defensive victories in August 1917 at Mărăști, Mărășești, and Oituz, battles that took place under the slogan “Pe aici nu se trece”(They shall not pass - you will go no further). This is where Pârvu died.
<figure>
<img src="./post2/map.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>Romania before and after first world war</i></figcaption>
</figure>

##The school
I am not sure what teacher training school Pârvu attended. The signatures of his colleagues (more on this later) indicate they came from different areas in the south of Romania, like Olt, Muscel and Dimbovita.  I think I found the school most likely to be his alma mater, though I hope to be able to check one day.
According to this [source](https://adevarul.ro/stiri-locale/pitesti/de-la-bucuresti-la-campulung-povestea-primei-2150498.html) the first teaching college also knows as Normal School in the south of the country was in a town called Campulung and set up in 1867 with the explicit purpose to train enough teachers to educate the population. Here is its selection policy: "The young man selected to become a teacher had to meet the following criteria: to be the son of a farmer born and raised in the countryside, to be no younger than 16 years old or older than 18 years old, to be a graduate of four primary classes with an outstanding grade, to have full health … The selected candidate had to bring a statement from the father by which he undertakes that his son will follow the school courses until graduation, and after graduation to stay at least six years at the post where he was assigned". 
Admissions criteria might have changed over time, the pupils in the photo below look younger than 16. The school must have been boarding given the geographic spread of its students. 
<figure>
<img src="./post2/schoolphotos.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>Photos from the quoted source, taken in 1910, 4 years after the latest date I can find in Pârvu’s school notes, show students of different ages and an interesting looking PE class! </i></figcaption>
</figure>

##The books
###Chemistry
Back to more personal evidence. For a number of years, I had Pârvu’s chemistry notes. They describe common metals zinc, chrome, nickel and various combinations, carbonate this, sulphide that, their characteristics, the process of making mirrors and many more. I loved the beautiful calligraphy, the silky paper, his obvious dedication to his studies, his signatures and dates (including this one of his surname, from 1903, year III). I loved the fact that his only grandchild, my mother, later became a chemistry teacher.
<figure>
<img src="./post2/chemistrynotes.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>Pârvu’s chemistry notes</i></figcaption>
</figure>
Recently, I found the source of these notes: an astonishing French book of chemistry, published by Librarie Hachette in 1896, elegant and in great condition. At the time, French was the language of educated Romanians so it's likely he knew it. 
<figure>
<img src="./post2/chemistrycover.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>Cover and inner page of the chemistry book and the first page of the book</i></figcaption>
</figure>
Other pages from the book:
<figure>
<img src="./post2/chemistryinside.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>More pages of the French book, plenty of illustrations and explanations of various processes from making steel, oil, alcohol, sugar and many more.
     </i></figcaption>
</figure>
I even found the old bookmark interesting, Parsival/ Parzival(?). I could not find much about what this might be.
<img src="./post2/chemistrybookmark.png"
     style="display: block; margin: auto; width: 400px" />


My sons had the idea of searching for the periodic table to find out how many elements were known then. Much to our surprise, there was no periodic table at all! 
We looked it up and found 1869 as the year of Medeelev’s discovery but it looks like by 1896 it was not yet in the chemistry textbook of the leading French publishing house.
The existence of this book makes me curious about different things. Why should he get a French chemistry book? It does feel too advanced. Was it because there were no Romanian books on chemistry at the time? Did he have a special interest in chemistry? How did he get the book? Did he order it somehow? A bookshop in Bucharest or Pitesti? Were French textbooks routinely stocked at the time? Unanswered questions.

###Geometry
The other book of Pârvu I found is a geometry book. This has always been my favourite subject and I thought I “got it” from my math teacher dad. It seems there are geometry connections in my family.
This is all together a very different book. It looks like home-bound, with frequent notes from Pârvu, like “We got till here by March 1903” or “Up till here in year II” and various comments, notes and annotations. From the notes, I believe the book was used for multiple school years. Some of his notes are bound into the book.
I think the French book must have been expensive so he kept it clean with notes on separate papers, while the geometry book is more of a cheaper workbook, annotated, signed on, scribbled on, way more personal.
The geometry book is in Romanian and is from 1899 (somehow the 10th edition at that time!). 
<figure>
<img src="./post2/geometryinside.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i> Inner cover and table of contents
     </i></figcaption>
</figure>

Some familiar problems:
<img src="./post2/geometryproblems.png"
     style="display: block; margin: auto; width: 400px" />
While the concepts have not changed for thousands of years, the language is very interesting. There are letters that no longer exist like e and i with a ˇ on top, plenty of archaic language, like crown  for a vertex (in Romanian “crestetul unghiului”) and sole, like sole of the foot (“talpa”)  for the base, excessively formal and latin words.
<figure>
<img src="./post2/geometrynotes.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i> Pârvu’s notes bound into the book with the same beautiful calligraphy
     </i></figcaption>
</figure>

Some kind of legal(copyright?) notice _“Unsigned copies will be pursued according to the law”_:
<img src="./post2/copyright.png"
     style="display: block; margin: auto; width: 400px" />
Probably the biggest surprise of the geometry book is that several of his colleagues signed the book usually with the title “Souvenir” and giving the name, sometimes the address in the form of a village and county. A memorable dedication, a beautifully calligraphed little poem (even prettier handwriting than my great-grandfather!)  from 1902 signed by a colleague, Ionita C Ion, says something like this (though a few commas wouldn’t have gone amiss):<br>
     _One forgets many things, <br>
     I forgot many things myself, <br>
     But one thing I won’t forget <br>
     It’s you, my friend_<br>
     I hope you had a good life, Ionita C Ion.
<figure>
     <img src="./post2/signatures.png"
          style="display: block; margin: auto; width: 400px" />
          <figcaption style="text-align: center;" ><i> Signatures and home addresses from colleagues on Pârvu’s geometry book
          </i></figcaption>
     </figure>
I get goosebumps everytime I hold these books and notes: the time, the history, the strive for education, the sacrifice.<br>
Rest in peace, great-granddad! 
`
     content = engText
    }
    const summary = Post2Summary(lang)
    return {
         content,
         linkId: summary.linkId,
         title: summary.cardTitle
    }
}

export default Post2;
